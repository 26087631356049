import { isPreview } from "src/lib/server/sanity";
import { ICommonAppData } from "src/lib/types/ICommonAppData";
import { GetServerSidePropsContext } from "next";
import NodeCache from "node-cache";

// memory caching for common app data:
//const CACHE_TIME = 1800000; // 30 mins
//const CACHE_TIME = 300000; // 5 mins
const CACHE_TIME = 600; // 10 mins
const cache = new NodeCache({
	stdTTL: CACHE_TIME, // seconds
	checkperiod: 300 // 0 = no check period. Default = 600
});




export async function setCommonAppData(commonAppData: ICommonAppData): Promise<void> {
	cache.set("commonAppData", commonAppData);
}

export async function clearCommonAppData() {
	cache.del("commonAppData");
    cache.flushAll();
}

export async function getCachedAppData(): Promise<ICommonAppData | null | undefined> {
	return cache.get("commonAppData");
}


// HTTP caching:
export function setDefaultCacheHeaders(context: GetServerSidePropsContext): void {
	const { res } = context;
	if (isPreview(context, true)) {
		res.setHeader(
			'Cache-Control',
			'no-store, no-cache, max-age=0, s-maxage=0, must-revalidate, proxy-revalidate'
		);
	} else {
		if( process.env.CACHE_HEADER ) {
			res.setHeader(
				'Cache-Control',
				process.env.CACHE_HEADER
			);
		} else {
			res.setHeader(
				'Cache-Control',
				'public, max-age=3600, s-maxage=3600, stale-while-revalidate=4200'
			);
		}

	}
}

export function setCacheHeaders(context: GetServerSidePropsContext, seconds: number): void {
	const { res } = context;
	if (isPreview(context, true)) {
		res.setHeader(
			'Cache-Control',
			'no-store, no-cache, max-age=0, s-maxage=0, must-revalidate, proxy-revalidate'
		);
	} else {
		res.setHeader(
			'Cache-Control',
			`public, max-age=${seconds}, s-maxage=${seconds}, stale-while-revalidate=${seconds + 600}`
		);
	}
}